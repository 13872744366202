.column {
  display: inline-block
  vertical-align: top
}

.column .box {
  border-top: $lightBorder
  padding: 20.5px 24px
  background: $White
}

.column .box:first-child {
  border-top: none
}

.heading {
  font-size: 2em
}

.heading-small {
  font-size: 1.3em
  margin-bottom: 16px
}

.small {
  font-size: 0.75em
}

.truncate {
  max-width: 100%
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  word-wrap: normal
}

.hidden {
  display: none !important
}

.hiddentext {
  color: transparent!important
  overflow: hidden
}

.screenreader {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.light {
  color: $Grey
}

.fakelink {
  padding: 0
  border: none
  background: none
  text-decoration: underline
  cursor: pointer

  &:hover,
  &:focus {
    outline: none
  }
}

.fakebtn {
  display: inline-block
  padding: 0.8em 1em
  color: $White
  background: $ModernizrPink
  text-decoration: none
  word-spacing: 0.1em
  &:focus {
    text-decoration: none
  }
}

.fakebtn--secondary {
  border: 1px solid $Grey
  background: transparent
  color: $DarkGrey
  &:hover {
    border-color: $DarkGrey
  }
}

.modal {
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(255, 255, 255, 0.8)
  z-index: 2
  overflow: auto
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
}
.modal-inner {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2)
  padding: 20px
  background-color: #fff
  width: 100%
  max-width: 500px
  overflow: auto
  line-height: 1.5em
}
