@import "../../node_modules/highlight.js/styles/github.css";
@import "../lib/normalize.css/normalize.css";
html {
  box-sizing: border-box;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  min-width: 320px;
  color: #272727;
}
html.font-loaded {
  font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background: #ececec;
  display: flex;
  flex-direction: column;
}
main {
  margin: 0 40px;
  min-height: calc(100vh - 120px);
  position: relative;
  transition-property: top, opacity;
  transition-duration: 0.3s;
  opacity: 1;
  top: 0;
}
.transition main {
  top: 200px;
  opacity: 0;
}
img {
  vertical-align: top;
}
a,
a:visited {
  color: #d81e75;
  text-decoration: none;
}
a:hover,
a:focus {
  outline: none;
  text-decoration: underline;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 750px) {
  main {
    min-height: calc(100vh - 70px);
    margin: 65px 10px;
  }
}
.column {
  display: inline-block;
  vertical-align: top;
}
.column .box {
  border-top: 1px solid #ececec;
  padding: 20.5px 24px;
  background: #fff;
}
.column .box:first-child {
  border-top: none;
}
.heading {
  font-size: 2em;
}
.heading-small {
  font-size: 1.3em;
  margin-bottom: 16px;
}
.small {
  font-size: 0.75em;
}
.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.hidden {
  display: none !important;
}
.hiddentext {
  color: transparent !important;
  overflow: hidden;
}
.screenreader {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
}
.light {
  color: #aaa8a9;
}
.fakelink {
  padding: 0;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}
.fakelink:hover,
.fakelink:focus {
  outline: none;
}
.fakebtn {
  display: inline-block;
  padding: 0.8em 1em;
  color: #fff;
  background: #d81e75;
  text-decoration: none;
  word-spacing: 0.1em;
}
.fakebtn:focus {
  text-decoration: none;
}
.fakebtn--secondary {
  border: 1px solid #aaa8a9;
  background: transparent;
  color: #444;
}
.fakebtn--secondary:hover {
  border-color: #444;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
  z-index: 2;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modal-inner {
  box-shadow: 0px 10px 30px rgba(0,0,0,0.2);
  padding: 20px;
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  overflow: auto;
  line-height: 1.5em;
}
.header {
  display: flex;
  width: 100%;
  height: 120px;
  transition: height 0.3s;
}
.header-logo {
  display: flex;
  min-width: 290px;
  background: #fff;
  justify-content: center;
  align-items: center;
}
.header a {
  display: flex;
  height: 100%;
  color: #444;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}
.header-logo a {
  justify-content: flex-start;
  padding-left: 40px;
}
.header-items {
  z-index: 3;
  display: flex;
  height: 120px;
  flex: 1 0 50%;
  align-items: flex-start;
}
.header-item {
  height: 100%;
  background: #fff;
  text-align: center;
  border-left: 1px solid #ececec;
  flex: 1;
}
.header-item:focus,
.header-item:hover,
.header-item.active {
  outline: none;
  background: #f7f7f7;
}
#header-items-toggle,
.header-items-toggle {
  display: none;
  outline: none;
}
.header-logo a {
  display: flex;
  width: 100%;
  height: 120px;
}
.header-logo a:focus {
  outline: none;
  opacity: 0.8;
}
.header-logo .logo {
  display: block;
  width: 100%;
  max-width: 200px;
  height: 37px;
  background: url("/img/logo.svg") center left no-repeat;
  background-size: contain;
  font-size: 0;
}
@media (min-width: 960px) {
  .header-logo .logo {
    position: relative;
    top: -4px;
  }
}
@media (max-width: 959px) and (min-width: 450px) {
  .header-logo .logo {
    background-size: auto 100%;
    width: 76px;
  }
}
@media (max-width: 960px) {
  .header-logo {
    flex: 0;
    min-width: 155px;
  }
}
@media (max-width: 750px) {
  .header-items {
    height: 60px;
    flex-wrap: wrap;
  }
  .header-item {
    min-width: 50%;
    background: #fff;
    border-bottom: 1px solid #ececec;
  }
}
@media (max-width: 450px) {
  .header {
    height: 70px;
    flex-wrap: wrap;
  }
  .header-logo {
    flex: 1;
  }
  .header-logo a {
    height: 70px;
    width: 100%;
  }
  .header-logo .logo {
    width: 105px;
  }
  .header-items-toggle {
    -webkit-user-select: none;
    user-select: none;
    display: block;
    min-width: 100px;
    border: none;
    border-left: 1px solid #ececec;
    background: url("/img/menu.svg") center no-repeat #fff;
    color: transparent;
  }
  #header-items-toggle:checked + .header-items {
    display: block;
  }
  .header-items {
    display: none;
    flex: auto;
    width: 100%;
  }
  .header-item {
    height: 60px;
  }
}
.footer {
  position: relative;
  display: flex;
  padding: 2em 40px;
  min-height: 150px;
  background: #d81e75;
  color: #ececec;
  font-size: 16px;
  line-height: 1.6;
  justify-content: space-between;
  align-items: flex-end;
}
.footer p {
  margin: 0 0 0.5em;
}
.footer a,
.footer .fakelink {
  color: #ececec;
  text-decoration: underline;
}
.footer a:focus,
.footer .fakelink:focus,
.footer a:hover,
.footer .fakelink:hover,
.footer a:active,
.footer .fakelink:active {
  outline: none;
  text-decoration: none;
}
.footer .hiddentext {
  display: inline-block;
  width: 38px;
  height: 38px;
  background-image: url("/img/github.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer-links {
  flex: 1 1 auto;
  width: 100%;
}
.footer-links>div {
  margin: 0.5em 0;
}
@media (min-width: 961px) {
  .builder .footer {
    padding-left: calc(290px + 1.2em * 1.4);
  }
}
@media (min-width: 1251px) {
  .builder .footer {
    padding-right: 30%;
  }
}
@media (max-width: 960px) {
  .footer {
    flex-direction: column;
    text-align: center;
    font-size: 16px;
  }
  .footer .hiddentext {
    margin: 1em auto 0;
  }
  .footer .footer-links {
    padding-bottom: 1em;
  }
}
@media (max-width: 750px) {
  .footer {
    font-size: 12px;
  }
  .footer-links>div {
    margin: 1em 0;
  }
}
.home {
  color: #444;
  margin: 65px 40px;
}
.home h1 {
  font-size: 5em;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.16em;
  letter-spacing: -0.02em;
}
.home h2,
.home h3,
.home h4,
.home h5,
.home h6 {
  font-weight: 100;
  margin: 1em 0 0.5em;
}
.home h3 {
  font-size: 2em;
}
.home .fakebtn {
  font-size: 28px;
  margin: 1em 0.5em 3em 0;
  color: #fff;
  text-align: center;
}
@media (max-width: 750px) {
  .home .fakebtn {
    width: 100%;
  }
}
.home .fakebtn:focus {
  color: #d81e75;
  background: #fff;
}
@media (max-width: 750px) {
  .home .fakebtn:not(.fakebtn--secondary) {
    margin-bottom: 1em;
  }
}
.home .fakebtn--secondary {
  color: #444;
}
@media (max-width: 750px) {
  .home .fakebtn--secondary {
    margin-top: 0;
  }
}
.home-heading {
  max-width: 12em;
}
.home-subheading {
  color: #666;
  max-width: 25em;
}
.home-info {
  display: flex;
  justify-content: space-between;
}
.home-blurbs {
  font-size: 1.3em;
  width: 40%;
}
@media (max-width: 750px) {
  .home-blurbs {
    font-size: 1.2em;
  }
}
.home-blurb {
  max-width: 30em;
  margin-bottom: 2em;
}
.home-blurb p {
  line-height: 1.5;
  color: #666;
  font-weight: 300;
}
@media (max-width: 750px) {
  .home-blurb p {
    line-height: 1.7;
  }
}
.home-blurb h3 {
  margin-top: 0;
}
.home-boxes {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  max-width: 55%;
  justify-content: space-between;
}
.home-box {
  width: calc(50% - 1em);
  margin-bottom: 2em;
}
.home-box .box {
  padding: 20px;
}
.home-box h3 {
  margin: 0;
}
.home-box li {
  padding-bottom: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-box a {
  color: #444;
  white-space: nowrap;
  padding-bottom: 1em;
}
.home-box a:hover,
.home-box a:focus,
.home-box a:active {
  color: #d81e75;
  text-decoration: none;
}
@media (max-width: 1200px) {
  .home h1 {
    font-size: 4em;
  }
}
@media (max-width: 450px) {
  .home {
    margin-top: 40px;
  }
}
@media (max-width: 750px) {
  .home h1 {
    font-size: 2.4em;
  }
  .home h3 {
    font-size: 1.6em;
  }
}
@media (max-width: 1250px) {
  .home-blurbs,
  .home-boxes {
    flex: 1;
    max-width: none;
  }
  .home-box {
    width: 80%;
    margin: 0 auto 2em;
    max-width: none;
  }
}
@media (max-width: 960px) {
  .home-info {
    flex-direction: column;
  }
  .home-blurbs {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .home-box {
    font-size: 0.8em;
    max-width: calc(50% - 1em);
    margin: 0 0 2em;
  }
  .home-box a {
    font-size: 1.2em;
  }
  .home-box .box {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  .home-box {
    max-width: none;
    width: 100%;
  }
}
.author a {
  color: #aaa8a9;
  text-decoration: underline;
}
.blogpost,
.previousPosts {
  max-width: 90%;
  width: 960px;
  margin: auto;
}
.blogpost p,
.previousPosts p {
  line-height: 1.4em;
}
.blogpost header,
.previousPosts header {
  text-align: center;
}
.blogpost ul,
.previousPosts ul {
  line-height: 1.4;
  list-style: initial;
  padding-left: 1.2em;
}
.blogpost-links {
  margin-top: 2em;
}
.blogpost-link {
  display: inline-block;
  float: left;
  overflow: hidden;
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.blogpost-link-next {
  float: right;
}
.previousPosts {
  border-top: 1px solid #d4d4d4;
}
.previousPosts h3 {
  color: #444;
  width: 100%;
  font-weight: bold;
  margin: 1em 0;
}
.previousPosts span {
  display: block;
  margin-bottom: 1em;
}
.docs {
  max-width: 90%;
  width: 960px;
  margin: 2.5em auto;
  padding: 0 2.5em;
  background: #fff;
  line-height: 1.4em;
}
.docs table,
.docs td,
.docs th {
  border: 1px solid #aaa8a9;
}
.docs td,
.docs th {
  padding: 0.5em;
}
.docs tr:nth-child(even) {
  background: #ececec;
}
.docs h3 {
  font-size: 1.17em;
}
abbr,
.functionArg {
  cursor: help;
}
.functionRef {
  padding: 0.5em;
}
code {
  background: #f7f7f7;
}
pre code {
  display: block;
  padding: 1em;
}
.pl-k {
  color: #a71d5d;
}
.pl-smi {
  color: #333;
}
.pl-c1,
.pl-s .pl-v {
  color: #0086b3;
}
.pl-c {
  color: #969896;
}
.pl-e,
.pl-en {
  color: #795da3;
}
.pl-s {
  color: #183691;
}
.note {
  color: #ccc;
}
.codeblock {
  overflow: visible;
  color: #333;
}
