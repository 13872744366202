.author a {
  color: $Grey
  text-decoration: underline
}

.blogpost,
.previousPosts {
  max-width: 90%
  width: 960px
  margin: auto

  p {
    line-height: 1.4em
  }

  header {
    text-align: center
  }

  ul {
    line-height: 1.4
    list-style: initial
    padding-left: 1.2em
  }
}

.blogpost-links {
  margin-top: 2em
}

.blogpost-link {
  display: inline-block
  float: left
  overflow: hidden
  max-width: 50%
  text-overflow: ellipsis
  white-space: nowrap
}

.blogpost-link-next {
  float: right;
}

.previousPosts {
  border-top: 1px solid #d4d4d4

  h3 {
    color: $DarkGrey
    width: 100%
    font-weight: bold
    margin: 1em 0
  }

  span {
    display: block
    margin-bottom: 1em
  }
}
