.footer {
  position: relative
  display: flex
  padding: 2em 40px
  min-height: $FooterHeight
  background: $ModernizrPink
  color: $BackgroundGrey
  font-size: 16px
  line-height: 1.6
  justify-content: space-between
  align-items: flex-end

  p {
    margin: 0 0 .5em
  }

  a, .fakelink {
    color: $BackgroundGrey
    text-decoration: underline
    &:focus,
    &:hover,
    &:active {
      outline: none
      text-decoration: none
    }
  }

  .hiddentext {
    display: inline-block
    width: 38px
    height: 38px
    background-image: url(/img/github.svg)
    background-position: center
    background-size: contain
    background-repeat: no-repeat
  }
}

.footer-links {
  flex: 1 1 auto
  width: 100%
  &>div {
    margin: 0.5em 0
  }
}

@media (min-width: $largeScreen + 1px) {
  .builder .footer {
    padding-left: calc(290px + 1.2em * 1.4)
  }
}

@media (min-width: $XLargeScreen + 1px) {
  .builder .footer {
    padding-right: 30%
  }
}

@media (max-width: $largeScreen) {
  .footer {
    flex-direction: column
    text-align: center
    font-size: 16px

    .hiddentext {
      margin: 1em auto 0
    }

    .footer-links {
      padding-bottom: 1em
    }
  }
}

@media (max-width: $mediumScreen) {
  .footer {
    font-size: 12px
  }
  .footer-links {
    &>div {
      margin: 1em 0
    }
  }
}
