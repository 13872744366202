.home {
  color: $DarkGrey
  margin: 65px 40px

  h1 {
    font-size: 5em
    margin-top: 0
    margin-bottom: 0
    line-height: 1.16em
    letter-spacing: -0.02em
  }

  h2, h3, h4, h5, h6 {
    font-weight: 100
    margin: 1em 0 0.5em
  }

  h3 {
    font-size: 2em
  }

  .fakebtn {
    font-size: 28px
    margin: 1em 0.5em 3em 0
    color: $White
    text-align: center

    @media (max-width: $mediumScreen) {
      width: 100%
    }

    &:focus {
      color: $ModernizrPink
      background: $White
    }
  }

  .fakebtn:not(.fakebtn--secondary) {
    @media (max-width: $mediumScreen) {
      margin-bottom 1em
    }
  }

  .fakebtn--secondary {
    color: $DarkGrey
    @media (max-width: $mediumScreen) {
      margin-top: 0
    }
  }
}

.home-heading {
  max-width: 12em
}

.home-subheading {
  color: $MidGrey
  max-width: 25em
}

.home-info {
  display: flex
  justify-content: space-between
}

.home-blurbs {
  font-size: 1.3em
  width: 40%
  @media (max-width: $mediumScreen) {
    font-size: 1.2em
  }
}

.home-blurb {
  max-width: 30em
  margin-bottom: 2em;

  p {
    line-height: 1.5
    color: $MidGrey
    font-weight: 300
    @media (max-width: $mediumScreen) {
      line-height: 1.7
    }
  }

  h3 {
    margin-top: 0
  }
}

.home-boxes {
  flex: 1
  display: flex
  flex-wrap: wrap
  max-width: 55%
  justify-content: space-between
}

.home-box {
  width: calc(50% - 1em)
  margin-bottom: 2em

  .box {
    padding: 20px
  }

  h3 {
    margin: 0
  }

  li {
    padding-bottom: 1em
    overflow: hidden
    text-overflow: ellipsis
  }

  a {
    color: $DarkGrey
    white-space: nowrap
    padding-bottom: 1em

    &:hover,
    &:focus,
    &:active {
      color: $ModernizrPink
      text-decoration: none
    }
  }
}

@media (max-width: 1200px) {
  .home h1 {
    font-size: 4em
  }
}

@media (max-width: $smallScreen) {
  .home {
    margin-top: 40px
  }
}

@media (max-width: $mediumScreen) {
  .home h1 {
    font-size: 2.4em
  }
  .home h3 {
    font-size: 1.6em
  }
}

@media (max-width: $XLargeScreen) {
  .home-blurbs,
  .home-boxes {
    flex: 1
    max-width: none
  }

  .home-box {
    width: 80%;
    margin: 0 auto 2em;
    max-width: none
  }
}

@media (max-width: $largeScreen) {
  .home-info {
    flex-direction: column
  }

  .home-blurbs {
    width: 100%
  }
}

@media (max-width: $mediumScreen) {
  .home-box {
    font-size: 0.8em
    max-width: calc(50% - 1em)
    margin: 0 0 2em

    a {
      font-size: 1.2em
    }

    .box {
      padding: 20px
    }
  }
}

@media (max-width: 600px) {
  .home-box {
    max-width: none
    width: 100%
  }
}
