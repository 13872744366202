html {
  box-sizing: border-box
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif
  min-width: 320px
  color: $Black

  &.font-loaded {
    font-family: 'Source Sans Pro', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif
  }
}

*, *:before, *:after {
  box-sizing: inherit
}

body {
  background: $BackgroundGrey
  display: flex
  flex-direction: column
}

main {
  margin: 0 40px
  min-height: "calc(100vh - %s)" % $HeaderHeight
  position: relative
  transition-property: top, opacity
  transition-duration: $TransitionDuration
  opacity: 1
  top: 0
}

.transition main {
  top: $TransitionHeight
  opacity: 0
}

img {
  vertical-align: top
}

a,
a:visited {
  color: $ModernizrPink
  text-decoration: none
}

a:hover,
a:focus {
  outline: none
  text-decoration: underline
}

ul {
  margin: 0
  padding: 0
  list-style: none
}

@media (max-width: $mediumScreen) {
  main {
    min-height: "calc(100vh - %s)" % $SmallHeaderHeight
    margin: 65px 10px
  }
}
