.header {
  display: flex
  width: 100%
  height: $HeaderHeight
  transition: height $TransitionDuration
}

.header-logo {
  display: flex
  min-width: $SidebarWidth
  background: $White
  justify-content: center
  align-items: center
}

.header a {
  display: flex
  height: 100%
  color: #444
  text-decoration: none
  text-transform: uppercase
  font-size: 16px
  letter-spacing: 0.05em
  font-weight: 600
  justify-content: center
  align-items: center
}

.header-logo a {
  justify-content: flex-start
  padding-left: 40px
}


.header-items {
  z-index: 3
  display: flex
  height: $HeaderHeight
  flex: 1 0 50%
  align-items: flex-start
}

.header-item {
  height: 100%
  background: $White
  text-align: center
  border-left: $lightBorder
  flex: 1

  &:focus,
  &:hover,
  &.active {
    outline: none
    background: $NavHover
  }
}

#header-items-toggle,
.header-items-toggle {
  display: none
  outline: none
}

.header-logo {
  a {
    display: flex
    width: 100%
    height: $HeaderHeight
    &:focus {
      outline: none
      opacity: 0.8
    }
  }

  .logo {
    display: block
    width: 100%
    max-width: 200px
    height: 37px
    background: url('/img/logo.svg') center left no-repeat
    background-size: contain
    font-size: 0
    @media (min-width: $largeScreen) {
      position: relative
      top: -4px
    }
    @media (max-width: $largeScreen - 1px) and (min-width: $smallScreen) {
      background-size: auto 100%
      width: 76px
    }
  }
}

@media (max-width: $largeScreen) {

  .header-logo {
    flex: 0
    min-width: 155px
  }
}

@media (max-width: $mediumScreen) {
  .header-items {
    height: $HeaderButtonHeight
    flex-wrap: wrap
  }

  .header-item {
    min-width: 50%
    background: $White
    border-bottom: $lightBorder
  }
}

@media (max-width: $smallScreen) {
  .header {
    height: $SmallHeaderHeight
    flex-wrap: wrap
  }

  .header-logo {
    flex: 1

    a {
      height: $SmallHeaderHeight
      width: 100%
    }

    .logo {
      width: 105px
    }
  }

  .header-items-toggle {
    -webkit-user-select: none
    user-select: none
    display: block
    min-width: 100px
    border: none
    border-left: $lightBorder
    background: url(/img/menu.svg) center no-repeat $White
    color: transparent
  }

  #header-items-toggle:checked + .header-items {
    display: block
  }

  .header-items {
    display: none
    flex: auto
    width: 100%
  }

  .header-item {
    height: $HeaderButtonHeight
  }
}
